import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  Reviews,
  CustomerReviewModal,
  Contact,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={fishermanBusiness.name}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.phone,
                {
                  link: "https://order.toasttab.com/online/metro-deli-4-palmer-ave",
                  text: "Order Online",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Reviews
                reviews={fishermanBusiness.reviews}
                as={"horizontalItem"}
                buttonBasic={false}
                buttonSize={"medium"}
                centerHeader={true}
                cardInlinePhoto={"left"}
                header={"Reviews"}
                imageCircular={false}
                imageSize={"small"}
                itemButtonFloat={false}
                itemCenterContent={false}
                itemsDivided={false}
                itemsPerRow={3}
                linkAs={"none"}
                onlyEvenRows={true}
                showImage={true}
                verticalItemAlign={"left"}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={"restaurant"}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
      _id
      primaryLocation {
        phoneNumber
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
